<template lang="pug">
  .castWrap
    .castContainer(v-if="loading")
      Spinner
    .castContainer(v-else)



</template>


<script>
import Spinner from 'vue-simple-spinner'

export default {
  name: 'MovieFullCast',
  components:{
    Spinner
  },
  props:{
    id:String
  },
  data(){
    return{
      cast:null,
      loading:true,
    }
  },
  metaInfo: {

  },
  async beforeRouteUpdate(to,from,next){

  },
  async beforeMount(){

  }
}
</script>
<style lang="scss" scope>
 
</style>
