<template lang="pug">
.mtes-review-list-wrap
  //- .mtes-review-list(v-if="!loading")
  .mtes-review-list()
    .mtes-review-sorting-hat
      //- span(@click="") hide spoilers
      div(v-for="n in 5")
        .sorting-hat.pointer(@click="filterStar(n)")
          img( v-if="n <= score || score == 'all'" src="../assets/img/awakescore1.png")
          img(v-else src="../assets/img/sleepyscore.png")
      .sorting-hat.pointer(@click="filterStar('all')") All reviews: 
        img( v-if="score == 'all'" src="../assets/img/awakescore1.png")
        img(v-else src="../assets/img/sleepyscore.png")
    transition(name="slideup")
      div(v-if="!loading")
        div(v-if="reviews.length >0")
          div(v-for="review in reviews")
            mtesReviewCard(
              :review="review"
            )
        div(v-else style="text-align:center;")
          h4 沒有評論
    .mtes-review-pagination-wrap(v-if="maxPage > 1")
      button.prev-page(v-if="page > 0" @click="prevPage(page-1)") {{page}}
      button.current-page {{page + 1}}
      button.next-page(v-if="page < (maxPage - 1)" @click="nextPage(page+1)") {{page + 2}}
  .mtes-review-list(v-if="loading")
    Spinner
</template>
<script>
import mtesReviewCard from '@/components/mtesReviewCard'
import Spinner from 'vue-simple-spinner'

export default {
  name: 'mtesReviewList',
  props: {
    id:String,
    type:String,    
    userOrCritic:String,
  },
  components:{
    mtesReviewCard,
    Spinner
  },

  data(){
    return{
      loading:true,
      showLess:true,
      reviews:null,
      
      page:0,
      maxPage:null,
      score:'all',
      noSpoilers:false,
      awake:'../assets/img/awakescore1.png',
      sleep:'../assets/img/sleepyscore.png'

    }
  },
  methods:{
    async init(){
      this.loading = true
      let reviews = await this.axios.get(`/api/${this.type}reviews/${this.userOrCritic}/${this.id}/${this.page}/${this.score}/${this.noSpoilers}`)
      this.reviews = reviews.data.reviews;
      this.maxPage = Math.ceil(reviews.data.count / 20)
      this.loading = false
    },
    async filterStar(starNumber){
      this.score = starNumber;
      this.page = 0;
      this.init();
    },
    async nextPage(page){
      this.page = page;
      this.init();
    }
  },
  async created(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.mtes-review-list-wrap{
  min-height:200px;
}
.mtes-review-sorting-hat{
  display:flex;
  align-items: center;
  .sorting-hat{
    display:flex;
    align-items:center;
    
    margin-right:10px;
  }
  img{
    width:25px;
    height:25px;
  }
}
.mtes-review-pagination-wrap{
  display:flex;
  justify-content:center;
  button{
    margin:0 5px;
  }
}
</style>
