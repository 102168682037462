<template lang="pug">
  .mtes-details-wrap
    //- MOVIE DETAILS
    .movie-details(v-if="model == 'Movie'")
      span.genre(v-for="(genre, index) in genres")
        router-link(:to="`/genre/movie/${genresEN[index]}`")
          span {{genre}}
      span.time <i class="far fa-hourglass"></i> 片長:
        span(v-if="runtime > 0") {{runtime}}分鐘
        span(v-else) &nbsp; -
      span.release           
        span(v-if="releaseDateTW") <i class="far fa-calendar"></i> 上映日期: {{releaseDateTW | luxon('MMMM月 dd日 yyyy')}}
        span(v-else-if="releaseDateUS") <i class="far fa-calendar"></i> 上映日期: {{releaseDateUS | luxon('MMMM月 dd日 yyyy')}}
    //- TV DETAILS
    .tv-details(v-if="model == 'TvSeries' || model == 'TvSeason' || model == 'TvEpisode' ")
      span.genre(v-for="(genre, index) in genres")
        router-link(:to="`/genre/tvSeries/${genresEN[index]}`")
          span {{genre}}
      span.release           
        span(v-if="airDate") <i class="far fa-calendar"></i> 上映日期: {{airDate | luxon('MMMM月 dd日 yyyy')}}
    //- STAR DETAILS
    .star-details(v-if="model == 'Star'")
      span.dob 生日:
        span(v-if="dob") &nbsp; {{dob| luxon('MMMM月 dd日 yyyy')}} 
        br
        span(v-if="age") {{age}}歲 
        br
        span(v-if="dod") &nbsp; {{star.dod| luxon('MMMM月 dd日 yyyy')}}
      span(v-if="zodiac") 星座: {{zodiac}} 
      br
              
</template>
<script>

export default {
  name: 'mtesDetails',
  props: {
    model:String,
    genres:Array,
    genresEN:Array,
    runtime:Number,
    releaseDateUS:String,
    releaseDateTW:String,
    airDate:String,
    dob:String,
    age:Number,
    dod:String,
    zodiac:String,
    id:String,
  },
  components:{

  },

  data(){
    return{

    }
  },
  methods:{
    
  },
  async created(){

  }
}
</script>
<style lang="scss" scoped>
  .movie-details, .tv-details{
    font-size:12px;
    .time{
      margin:0 20px 0 10px;
    }
    .genre{
      border:solid 1px lightgrey;
      padding:5px 10px;
      border-radius:5px;
      margin-right:10px;  
      margin-bottom:10px;
      display:inline-block;    
    }
  }
  .release, .movie-details .time{
    display:inline-block;
    margin-top:20px;
  }
</style>
