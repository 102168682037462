<template lang="pug">
  .mtes-review-card(v-if="review.user")
    router-link.mtes-review-info(:to="`/profile/${review.user._id}`")
      .mtes-review-user()
        img.user-poster(v-if="review.user.thumbnail"  v-bind:class="{active: review.critic}" v-bind:src="review.user.thumbnail")
        img.user-poster(v-else v-bind:class="{active: review.critic}" src="@/assets/img/awakescore.png")
      p {{review.user.displayName}}
      p(v-if="review.critic") 評論家
    .mtes-review-content
      v-clamp(v-if="review.content" autoresize :max-lines="4" style="line-height:24px;") {{review.content}}
        template( #after="{ toggle, expanded, clamped }")
          span.pointer(
            style="margin-top:20px; margin-left:20px;"
            v-if="expanded || clamped"
            class="toggle btn btn-sm"
            @click="toggle") <br> <div style="text-align:right;">{{clamped ? '更多' : '較少'}}</div>
      p(v-if="review.critic" style="text-align:right;")
        a(:href="review.articleLink") Read full review
      div(v-else-if="review.content" style="margin-bottom:30px;")
      .mtes-review-tofu-wrap
        div(v-for="n in 5")
          img.mtes-review-tofu(v-if="n <= review.score" src="../assets/img/awakescore1.png")
          img.mtes-review-tofu(v-else src="../assets/img/sleepyscore.png")

    
              
</template>
<script>
import VClamp from 'vue-clamp'

export default {
  name: 'mtesReviewCard',
  props: {
    items:Array,
    listName:String,
    type:String,
    showLessNumber:Number,
    review:Object,
  },
  components:{
    VClamp
  },

  data(){
    return{
      showLess:true,
    }
  },
  methods:{
    imageLoadError(event){
      event.target.classList.toggle('active')
      event.target.nextSibling.classList.toggle('active')
    }
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
.user-poster.active{
  box-shadow:0px 0px 18px 2px gold;
}
.mtes-review-card{
  margin:20px 0;
  display:flex;
  justify-content:space-between;
}
.mtes-review-tofu-wrap{
  display:flex;
}
.mtes-review-card:nth-child(even){
  flex-direction: row-reverse;
  .mtes-review-tofu-wrap{
    flex-direction: row-reverse;
  }
  
}
.mtes-review-content{
  width:calc(95% - 60px);
  padding:10px;
  border:1px solid #e6e6e6;
  border-radius:5px;
}
.mtes-review-tofu{
  width:23px;
  height:23px;
  margin: 0 3px;
}
.mtes-review-info{
  width:60px;
  text-align: center;
  img{
    width:100%;
    border-radius:3px;
  }
  p{
    font-size:12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
