<template lang="pug">
.yt-popup-wrap(v-if="loaded")
  .yt-modal( v-on:keyup.esc="close")
    Spinner
    iframe(  v-on:keyup.esc="close" class="video" v-bind:src="url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;`" allowfullscreen)
  .background(@click="close")
  
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {  
  name: 'YoutubePopup',
  components:{
    Spinner
  },
  props:{
    ytUrl:String,
    ytState:Boolean,
    
  },
  computed:{
    // ytWidth:()=>{
    //   return 
    // }
  },
  data(){
    return{      
      embed:null,
      loaded:false,
      url:'https://www.youtube.com/embed/bPHJnsW18Lw'
     
    }
  },
  methods:{
    async close(){
      this.$emit('closeYT')
    },
    navKeyNavigation(e){      
      if(e.key == 'Escape') this.close(); 
    },
    onkeydown(e){
      this.navKeyNavigation(e)
    },
    
  },
  mounted() {
    document.onkeydown = this.onkeydown
  },
  async created(){
    if( this.ytUrl.indexOf('=')){
      var abc = this.ytUrl.indexOf('=')
      this.url = `https://www.youtube.com/embed/${this.ytUrl.slice(abc + 1)}?autoplay=1`
      this.loaded = true
    }
  }
    
}
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.yt-popup-wrap{
  position:fixed;
  width:100%;
  display:flex;
  justify-content:center;
  z-index: 999;
}
.yt-modal{
  width:50%;
  z-index: 999;
  position: relative;
  padding-bottom: calc(28%); /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
}
iframe{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}
  // .yt-modal{
  //   position:fixed;
  //   z-index: 101;
  //   width:50%;
  //   left:25%;
  //       // width: 100%;
  //   height: 100vh;
  //   // padding-bottom: 56.25%;
  // }
  // .video {
  // //   position: absolute;
  // //   top: 0;
  // //   left: 0;
  //   width: 100%;
  //   // height: 100%;
  // }
  .background{
    top:0;
    z-index: 100;
    position:fixed;
    width:100%;
    height:100%;
    background: rgba(0,0,0,.5)
    
  }
  @media screen and (max-width:768px) {
    .yt-modal{
      width:80%;
      padding-bottom: calc(52%); /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
    }
  }
</style>
