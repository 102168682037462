<template lang="pug">
  .castListWrap()
    div(v-if="!isLoading")
      mtesList(
        :items="cast"
        :listName="'演員列表'"
        :type="`star`"
        :showLessNumber="10"
      )
    div(style="height:50vh" v-else)
      Spinner
              
</template>
<script>
import Spinner from 'vue-simple-spinner'
import mtesList from '@/components/mtesList'
export default {
  name: 'mtesCast',
  props: {
    type:String,
    id:String,
  },
  components:{
    mtesList,
    Spinner
  },

  data(){
    return{
      cast:null,
      isLoading:true,
    }
  },
  methods:{
    
  },
  async created(){
    try{
      var response = await this.axios.get(`/api/${this.type}cast/${this.id}`)
      if (response.data.err == true) throw response.data.msg 
      this.cast =response.data.cast
      this.isLoading = false
    }catch(err){
      this.flash(err,'error')
    }
  }
}
</script>
<style lang="scss" scoped>
.castListWrap{
  width:100%;
  padding:5px;
}
</style>
